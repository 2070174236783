import { Component, Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UsuarioService } from '../../../shared/service/usuario.service';
import { Usuario } from '../../../shared/model/usuario.model';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { GenericComponent } from 'src/app/components/generic/generic.component';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ClienteService } from 'src/app/shared/service/cliente.service';
import { Cliente } from 'src/app/shared/model/cliente.model';
import { Contrato } from 'src/app/shared/model/contrato.model';
import { ContratoService } from 'src/app/shared/service/contrato.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Role } from 'src/app/shared/model/role';
import { Grupo } from 'src/app/shared/model/grupo.model';
import { GrupoService } from 'src/app/shared/service/grupo.service';

@Component({
  selector: 'app-usuario-form',
  templateUrl: './usuario-form.component.html',
  styleUrls: ['./usuario-form.component.css'],
  providers: [UsuarioService, GrupoService]
})

@Injectable()
export class UsuarioFormComponent extends GenericComponent<Usuario> {

  public clienteOptions: Cliente[];
  public contratoOptions: Contrato[];  
  public grupoOptions: Grupo[];

  constructor(protected service: UsuarioService,
    protected router: Router,
    private formBuilder: FormBuilder,
    protected authService: AuthService,
    private route: ActivatedRoute,
    private clienteService: ClienteService,
    private contratoService: ContratoService,
    private grupoService: GrupoService,
    private _snackBar: MatSnackBar,
    ) {

    super(authService)
  }

  public form = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required],
    nome: ['', Validators.required],
    grupo: ['', Validators.required],
    cliente: [''],    
    contrato: [''],    
  })



  ngOnInit() {
    if(this.isGranted([Role.Admin])){
      this.clienteService.list().subscribe(
        (data: Cliente[]) => this.clienteOptions = data
      );

      this.contratoService.list().subscribe(
        (data: Contrato[]) => this.contratoOptions = data
      );

      this.grupoService.list().subscribe(
        (data: Grupo[]) => this.grupoOptions = data
      )
    }


    this.route.params.subscribe((parametros: Params) => {
      if (this.isGranted(["ROLE_MASTER"]))
        this.form.get('grupo').validator = null;
      if ('selector' in parametros) {
        this.loadRegister(parametros.selector)
        this.form.get('username').disable();
        this.form.get('password').validator = null;
        this.form.get('confirmPassword').validator = null;        
      }
    })
  }  

  save(data) {          
    if (this.form.invalid) {
      return false;
    }
    console.log(data)
    if(data.password != data.confirmPassword){
      console.log(`${data.password} | ${data.confirmPassword}`)
      this._snackBar.open("As senhas digitadas devem ser iguais", "", { duration: 3000, panelClass: ['app-alert'] }) 
      return false;      
    }
    this._snackBar.dismiss()

    let passwordData: any = {}
    passwordData.password = data.password
    passwordData.confirmPassword = data.confirmPassword
    // data.password = passwordData
    // delete data.confirmPassword

    const userFormObject = Object.assign(data, { password: passwordData })
    delete userFormObject.confirmPassword

    if(!this.element){
      super.new(userFormObject, '/admin/usuario')
    } else {
      delete userFormObject.username

      super.edit(userFormObject, this.element.selector, 'admin/usuario')
    }
  }

  protected populateElement(data : {}){
    const grupoSelector = this.element.grupo && this.element.grupo.selector ? this.element.grupo.selector : "";
    return { 
      ...data,
      cliente: this.element.cliente.selector,
      contrato: this.element.contrato.selector,
      grupo: grupoSelector
    }
  }

}
