// Endereço MT PRD
const baseUrl = "https://webservice.homelock.syssegmt.mt.gov.br";

// Endereço MT HML
//const baseUrl = "https://webservice.hml.homelock.syssegmt.mt.gov.br";

//Endereço MT DEV
//const baseUrl = "https://webservice.dev.homelock.syssegmt.mt.gov.br"

export const urlList =  {
    "auth": {
        "login": baseUrl+"/login_check",
    },
    "solicitacao": {
        "list": baseUrl+"/api/solicitacao",    
        "new": baseUrl+"/api/solicitacao",
        "edit": baseUrl+"/api/solicitacao",
        "view": baseUrl+"/api/solicitacao",
        "aprovar": baseUrl+"/api/solicitacao",
        "recusar": baseUrl+"/api/solicitacao",
        "list_pendente": baseUrl+"/api/solicitacao_pendente",
        "list_aprovada_ultima": baseUrl+"/api/solicitacao_aprovada_ultima",
    },
    "contrato": {
        "list": baseUrl+"/api/contrato",    
        "new": baseUrl+"/api/contrato",
        "edit": baseUrl+"/api/contrato",
        "view": baseUrl+"/api/contrato",
        "list_package": baseUrl+"/api/contrato",
        "delete_package": baseUrl+"/api/package_contrato",
        "add_package": baseUrl+"/api/package_contrato",
        "list_dispositivo": baseUrl+"/api/contrato",
        "delete_dispositivo": baseUrl+"/api/dispositivo_pre_aprovado",
        "add_dispositivo": baseUrl+"/api/dispositivo_pre_aprovado",        
    },
    "grupo": {
        "list": baseUrl+"/api/grupo",
        "new": baseUrl+"/api/grupo",
        "edit": baseUrl+"/api/grupo",
        "view": baseUrl+"/api/grupo",
        "list_package": baseUrl+"/api/grupo",
        "delete_package": baseUrl+"/api/package_grupo",
        "add_package": baseUrl+"/api/package_grupo",
    },
    "cliente": {
        "list": baseUrl+"/api/cliente",    
        "new": baseUrl+"/api/cliente",
        "edit": baseUrl+"/api/cliente",
        "view": baseUrl+"/api/cliente",
    },       
    "dispositivo": {
        "list": baseUrl+"/api/dispositivo",    
        "new": baseUrl+"/api/dispositivo",
        "edit": baseUrl+"/api/dispositivo",
        "view": baseUrl+"/api/dispositivo",
        "ativar": baseUrl+"/api/dispositivo",
        "desativar": baseUrl+"/api/dispositivo",                
        "alterar_senha": baseUrl+"/api/dispositivo",
        "delete_package": baseUrl+"/api/package_dispositivo",
        "add_package": baseUrl+"/api/package_dispositivo",
        "list_package": baseUrl+"/api/dispositivo",
        "bloquear": baseUrl+"/api/dispositivo",
        "desbloquear": baseUrl+"/api/dispositivo",
    },      
    "usuario": {
        "list": baseUrl+"/api/usuario",    
        "new": baseUrl+"/api/usuario",
        "edit": baseUrl+"/api/usuario",
        "view": baseUrl+"/api/usuario",
    },
    "contra-senha": {
        "gerar": baseUrl+"/api/contra-senha",    
    },
}